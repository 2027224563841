import React from "react";
import styled from "styled-components";
import { Layout } from "antd";

const { Content } = Layout;

const LayoutWrapper = styled.div`
  height: 100%;
  padding: 2rem 4rem;

  @media (max-width: ${(props) => props.theme.breakpointMobile}) {
    /** added padding to account for the menu button's width */
    padding: 5rem 1rem 3rem;
  }
`;

const ModuleLayout: React.FC = ({ children }) => {
  return (
    <Layout style={{ height: "100%" }}>
      <LayoutWrapper>
        <Content style={{ height: "100%" }}>{children}</Content>
      </LayoutWrapper>
    </Layout>
  );
};

export default ModuleLayout;
