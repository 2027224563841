import { isDefined } from "./null-checks";

/* eslint-disable no-restricted-globals */
const isUpper = (s: string) => s === s.toUpperCase();
const isLower = (s: string) => s === s.toLowerCase();

const toUpperAt = (s: string, index: number) => {
  if (index >= s.length || index < 0) return s;
  return (
    s.substring(0, index) +
    s.charAt(index).toUpperCase() +
    s.substring(index + 1)
  );
};

const acronyms = [
  "CAGR",
  "EV",
  "EBITD",
  "EBITDA",
  "EPS",
  "FOCF",
  "PB",
  "PCF",
  "PE",
  "PFCF",
  "PS",
  "PTBV",
  "ROA",
  "ROAA",
  "ROAE",
  "ROE",
  "ROI",
  "RFY",
  "TBV",
  "YOY",
];

const capitalizeAcronyms = (s: string) => {
  const matchedAcronym = acronyms.find(
    (acronym) => acronym === s.toUpperCase()
  );

  return isDefined(matchedAcronym) ? matchedAcronym : s;
};

export const parseCamelCase = (
  camelCaseString: string,
  customOverrides: Record<string, string> = {}
): string => {
  const shouldInsertSpace = (
    curr: string,
    index: number,
    chars: string[]
  ): boolean => {
    // never insert space before the first character
    if (index === 0) return false;

    const prev = chars[index - 1];

    // do not insert space if current and previous char are numbers
    if (!isNaN(Number(curr)) && !isNaN(Number(prev))) return false;

    // insert space in front and behind slash "/"
    if (curr === "/" || prev === "/") return true;

    if (isUpper(curr) && isLower(prev)) return true;

    return false;
  };

  const keepSpaceInsertionLocs = (
    accumulator: number[],
    shouldAddSpace: boolean,
    spaceLoc: number
  ) => {
    return shouldAddSpace ? [...accumulator, spaceLoc] : accumulator;
  };

  const splitByIndices = (s: string, indices: number[]): string[] => {
    return indices.map((spaceLoc, currIndex, spaceArray) => {
      const isLastElem = currIndex >= spaceArray.length - 1;
      return isLastElem
        ? s.substring(spaceLoc)
        : s.substring(spaceLoc, spaceArray[currIndex + 1]);
    });
  };

  // Step 0: Return custom override, if defined
  if (isDefined(customOverrides[camelCaseString])) {
    return customOverrides[camelCaseString];
  }

  // Step 1: Ensure that first letter is uppercased
  const UpperCamelString = toUpperAt(camelCaseString, 0);

  // Step 2: identify where spaces should be inserted
  // indices at which the string should be spliced into words
  const spliceIndices = UpperCamelString.split("")
    .map(shouldInsertSpace)
    .reduce(keepSpaceInsertionLocs, [0]);

  // Step 3: split the original string into words
  const words = splitByIndices(UpperCamelString, spliceIndices);

  // Step 4: ensure first letter of each split word is capitalized
  const capitalizedWords = words.map((word) => toUpperAt(word, 0));

  // Step 5: capitalize acronyms
  const acronymCapitalizedWords = capitalizedWords.map((word) =>
    capitalizeAcronyms(word)
  );

  // Step 6: add spaces between each word
  return acronymCapitalizedWords.join(" ");
};
