import { WebClient } from "@slack/web-api";
import { useCallback } from "react";
import { isNullable } from "lib/utils";

const token = process.env.REACT_APP_SLACK_BOT_TOKEN;

if (isNullable(token)) {
  throw new Error(`
    To submit product feedback, the following environment variable must be
    defined:
    REACT_APP_SLACK_BOT_TOKEN
  `);
}

const TOKEN = token;
const CHANNEL_ID = "C019YA3358Q";

const slackClient = new WebClient(TOKEN);

const useProductFeedback = () => {
  const submitFeedback = useCallback(
    ({ text, email }: { text: string; email?: string }) => {
      return slackClient.chat.postMessage({
        channel: CHANNEL_ID,
        text,
        username: email || "anonymous",
      });
    },
    []
  );

  return { submitFeedback };
};

export default useProductFeedback;
