import React, { useState } from "react";
import { Form, Input, message, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import useProductFeedback from "./useProductFeedback";
import { SharedModalProps } from "./modal-config.model";

const FeatureRequestModal: React.FC<SharedModalProps> = ({
  visible,
  onCancel,
  onOk,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = useForm();
  const { submitFeedback } = useProductFeedback();

  const submissionSuccessMessage = "Thanks for your feedback!";
  const submissionErrorMessage = (
    <span>
      An error has occurred and we&apos;re sorry we couldn&apos;t receive your
      feedback.
      <br />
      We have recorded this incident and will study it carefully.
    </span>
  );

  /** How long the error message will be displayed, in seconds. */
  const ERR_MSG_DURATION = 6;

  return (
    <Modal
      title="Request a new feature"
      visible={visible}
      centered
      confirmLoading={confirmLoading}
      okText="Submit"
      onCancel={onCancel}
      onOk={() => {
        form.validateFields().then((values) => {
          setConfirmLoading(true);

          submitFeedback({ text: values.feedback, email: values.email })
            .then(() => {
              message.success(submissionSuccessMessage);
              setConfirmLoading(false);
              onOk();
            })
            .catch((err) => {
              console.error(err);
              message.error(submissionErrorMessage, ERR_MSG_DURATION);
              setConfirmLoading(false);
            });
        });
      }}
    >
      <div data-testid="feature-request-modal">
        <div style={{ marginBottom: "2rem" }}>
          We would love to hear your ideas! Type in your suggestions below and
          let our team work on bringing them into reality.
        </div>
        <Form form={form} layout="vertical">
          <Form.Item
            label="Email (optional)"
            name="email"
            rules={[
              {
                type: "email",
                message: "Please type in a valid email.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["feedback"]}
            label="Feature request"
            rules={[
              {
                required: true,
                message: "Please type in some suggestions before submitting.",
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 8 }} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default FeatureRequestModal;
