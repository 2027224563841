import React, { useMemo } from "react";
import {
  HomeOutlined,
  CalculatorOutlined,
  LayoutFilled,
  FileSearchOutlined,
  UnorderedListOutlined,
  StockOutlined,
  AreaChartOutlined,
  TableOutlined,
  PieChartOutlined,
  LoginOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated } from "store/auth/auth.selectors";
import { login, logout } from "store/auth/auth.slice";
import NavBarCreator, { NavbarItem, NavbarOptions } from "./internal/NavbarCreator";

const StyleWrapper = styled.div`
  .ant-menu {
    border: none;
  }
`;

interface NavBarProps {
  /**
   * A callback function which will be invoked when a menu item is selected.
   */
  onClick?: () => void;
}

const staticNavbarItems: NavbarItem[] = [
  {
    name: "Home",
    logo: <HomeOutlined />,
    link: "/",
  },
  {
    name: "Valuation Models",
    logo: <LayoutFilled />,
    link: "/",
    subMenu: [
      {
        name: "Discounted Cash Flow",
        logo: <TableOutlined />,
        link: "/dcf",
      },
      {
        name: "Gordon Growth",
        logo: <AreaChartOutlined />,
        link: "/gordon",
      },
      {
        name: "Financial Services Firm",
        logo: <StockOutlined />,
      },
      {
        name: "Young/High-Growth Firm",
        logo: <StockOutlined />,
      },
    ],
  },
  {
    name: "Equity Research",
    logo: <FileSearchOutlined />,
    link: "/equity-research",
  },
  {
    name: "Valuation Model Picker",
    logo: <UnorderedListOutlined />,
    link: "/model-picker",
  },
  {
    name: "Option Pricing Calculator",
    logo: <CalculatorOutlined />,
    link: "/opcalc",
  },
  {
    name: "Portfolio Visualizer",
    logo: <PieChartOutlined />,
  },
];

const menuOptions: NavbarOptions = {
  mode: "inline",
};

export const NavBar: React.FC<NavBarProps> = ({ onClick }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const loginItemDef = useMemo<NavbarItem>(
    () => ({
      name: "Login / Signup",
      logo: <LoginOutlined />,
      onClick: () => dispatch(login()),
    }),
    [dispatch]
  );

  const logoutItemDef = useMemo<NavbarItem>(
    () => ({
      name: "Sign out",
      logo: <LogoutOutlined />,
      onClick: () => dispatch(logout()),
    }),
    [dispatch]
  );

  const menuDef = useMemo(
    () => [
      ...staticNavbarItems,
      isAuthenticated ? logoutItemDef : loginItemDef,
    ],
    [isAuthenticated, loginItemDef, logoutItemDef]
  );

  return (
    <StyleWrapper>
      <NavBarCreator spec={menuDef} options={menuOptions} onClick={onClick} />
    </StyleWrapper>
  );
};
