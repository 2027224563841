/**
 * Given an object, return a new object with only the property keys you're
 * interested in.
 *
 * @param object the original object to be filtered.
 * @param keys   properties that will be retained in the object returned.
 */
export const filterObjectByKeys = <T>(
  object: Record<string | number, T>,
  keys: (keyof typeof object)[]
) => {
  const keysLookupTable = new Set(keys);

  return Object.entries(object)
    .filter(([key]) => keysLookupTable.has(key))
    .reduce(
      (acc, [key, val]) => ({
        ...acc,
        [key]: val,
      }),
      {} as Record<string | number, T>
    );
};
