import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu, Tooltip } from "antd";
import { isNullable } from "lib/utils";

const { SubMenu } = Menu;

export interface NavbarItem {
  logo: JSX.Element;
  name: string;
  link?: string;
  subMenu?: NavbarItem[];
  onClick?: () => void;
}

export interface NavbarOptions {
  defaultOpenKeys?: string[];
  mode?:
    | "vertical"
    | "vertical-left"
    | "vertical-right"
    | "horizontal"
    | "inline"
    | undefined;
}

interface NavBarCreatorProps {
  spec: NavbarItem[];
  options?: NavbarOptions;
  onClick?: () => void;
}

/**
 * Find the 'name' of a tab, given a relative path.
 *
 * @param relativePath
 *     A relative path, often the one that belongs to the current URL.
 *     Example relative paths include '/home', '/error/description', etc.
 *
 * @param menuDef
 *     An array of `MenuItem`s. The array is expected to define the app's navbar.
 *
 * @param cb
 *     Optionally provide a callback function, can be used to format the tab
 *     name that has been found.
 *
 * @param notFoundFallback
 *     Optionally define a string which will be used in case no tab name could
 *     be located with the given relative path. Defaults to an empty string.
 */
const resolveTabname = (
  relativePath: string,
  menuDef: NavbarItem[],
  cb = (val: string) => val,
  notFoundFallback = ""
): string => {
  // store the name of the tab found, if any, here.
  let tabFound: string | undefined;

  // perform DFS to find the first MenuItem which has the same pathname
  // as the one provided in the argument.
  const findLink = (menus?: NavbarItem[]): NavbarItem | undefined => {
    if (!menus) return;

    return menus.find((menu) => {
      const foundMatchLink = menu.link === relativePath;

      if (foundMatchLink) {
        tabFound = menu.name;
      }

      return foundMatchLink || findLink(menu.subMenu);
    });
  };

  findLink(menuDef);

  return cb(tabFound || notFoundFallback);
};

const NavBarCreator: React.FC<NavBarCreatorProps> = ({
  spec,
  options,
  onClick = () => {},
}: NavBarCreatorProps) => {
  const nameToKey = (name: string) => name.toLowerCase().split(" ").join("-");

  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState(
    resolveTabname(location.pathname, spec, nameToKey, "Home")
  );

  // recalculate the tab selection if the page's relative path has changed.
  useEffect(() => {
    setSelectedTab(resolveTabname(location.pathname, spec, nameToKey, "Home"));
  }, [location.pathname, spec]);

  const menuItemCreator = (menuItem: NavbarItem) => {
    const leafMenuItem = (item: NavbarItem) => {
      const isDisabled = isNullable(item.link) && isNullable(item.onClick);

      return (
        <Menu.Item
          key={nameToKey(item.name)}
          icon={item.logo}
          disabled={isDisabled}
          onClick={item.onClick}
        >
          {item.link ? (
            <Link to={item.link}>
              <span>{item.name}</span>
            </Link>
          ) : (
            <Tooltip
              title="Feature under development"
              placement="right"
              mouseEnterDelay={0.5}
            >
              <span>{item.name}</span>
            </Tooltip>
          )}
        </Menu.Item>
      );
    };

    const subMenuWrapper = (parent: NavbarItem) => (
      <SubMenu
        key={nameToKey(parent.name)}
        icon={parent.logo}
        title={
          <span>
            <span>{parent.name}</span>
          </span>
        }
      >
        {parent.subMenu ? parent.subMenu.map((item) => leafMenuItem(item)) : ""}
      </SubMenu>
    );

    return menuItem.subMenu ? subMenuWrapper(menuItem) : leafMenuItem(menuItem);
  };

  return (
    <div className="navbar">
      <Menu
        defaultOpenKeys={options?.defaultOpenKeys?.map(nameToKey) || []}
        mode={options?.mode || undefined}
        selectedKeys={[selectedTab]}
        onClick={({ key }) => {
          const resolvedKey = typeof key === "number" ? `${key}` : key;
          setSelectedTab(resolvedKey);
          onClick();
        }}
      >
        {spec.map((menuItemSpec) => menuItemCreator(menuItemSpec))}
      </Menu>
    </div>
  );
};

export default NavBarCreator;
