import React from "react";
import { Modal, Button } from "antd";
import { SharedModalProps } from "./modal-config.model";

export const UpcomingFeaturesModal: React.FC<Omit<
  SharedModalProps,
  "onOk"
>> = ({ visible, onCancel }) => {
  return (
    <Modal
      title="Our upcoming features"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <span key="hint-text" style={{ marginRight: "1rem" }}>
          Ready?
        </span>,
        <Button
          type="primary"
          key="submit-button"
          style={{ fontWeight: 600, borderRadius: "20px" }}
        >
          <a
            href="https://trello.com/b/TEbW4g57/mfg-app-feature-pipeline"
            target="_blank"
            rel="noopener noreferrer"
          >
            See our feature pipeline
          </a>
        </Button>,
      ]}
    >
      <div data-testid="upcoming-features-modal">
        <p>At MFG, we like to be transparent about what we’re working on.</p>
        <p>
          For this reason, we publish a list of features that we’re working on
          or thinking about.
        </p>
        <p>
          We review this list at the beginning of each month to make sure it’s
          up-to-date.
        </p>
      </div>
    </Modal>
  );
};

export default UpcomingFeaturesModal;
