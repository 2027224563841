import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { useDelay } from "hooks";
import { FileUnknownOutlined } from "@ant-design/icons";
import { isDefined } from "lib/utils";

interface ImageWithFallbackProps {
  src: string;
  alt: string;
  /** The height and width of the placeholder, in `rem`. */
  dimension: number;
  /**
   * Optionally specify a graceful wait period: if the image loaded successfully
   * within this duration, the placeholder will not be displayed.
   *
   * This can avoid the placeholder flickering under fast internet conditions.
   *
   * Unit: miliseconds.
   * Default: 200ms.
   */
  delay?: number;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
  src,
  alt,
  dimension,
  delay,
}) => {
  const DELAY_DEFAULT = 200;

  const [logoLoaded, setLogoLoaded] = useState(false);
  const [loadErred, setLoadErred] = useState(false);
  const showPlaceholder = useDelay(isDefined(delay) ? delay : DELAY_DEFAULT);

  const Placeholder = () => (
    <LoadingPlaceholder
      dimension={dimension}
      visible={showPlaceholder}
      className={showPlaceholder ? "pulsing" : ""}
    />
  );

  const ErrorFallback = () => (
    <FileNotFoundContainer dimension={dimension * 0.5}>
      <FileUnknownOutlined />
    </FileNotFoundContainer>
  );

  return (
    <>
      {!logoLoaded && !loadErred && <Placeholder />}
      {loadErred && <ErrorFallback />}
      {!loadErred && (
        <img
          style={{
            width: "100%",
          }}
          src={src}
          alt={alt}
          onError={() => setLoadErred(true)}
          onLoad={() => setLogoLoaded(true)}
        />
      )}
    </>
  );
};

export default ImageWithFallback;

const pulsingTheme = {
  light: "rgba(34, 34, 34, 0.089)",
  dark: "rgba(32, 32, 32, 0.151)",
};

const pulsing = keyframes`
  0% {
    background-color: ${pulsingTheme.light};
  }
  50% {
    background-color: ${pulsingTheme.dark};
  }
  100% {
    background-color: ${pulsingTheme.light};
  }
`;

interface LoadingPlaceholderProps {
  dimension: number;
  visible: boolean;
}

const LoadingPlaceholder = styled.div<LoadingPlaceholderProps>`
  background: transparent;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: ${(props) => props.dimension}rem;
  width: ${(props) => props.dimension}rem;
  border-radius: 0.5rem;
  margin: auto;
  transition: opacity 1s;

  &.pulsing {
    animation-name: ${pulsing};
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
`;

const FileNotFoundContainer = styled.div<{ dimension: number }>`
  font-size: ${(props) => props.dimension}rem;
  opacity: 40%;
`;
