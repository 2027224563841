import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModuleLayout from "components/shared/ModuleLayout";
import ModuleHeader from "components/shared/ModuleHeader";
import { SearchOutlined, RiseOutlined, BlockOutlined } from "@ant-design/icons";
import { PageHeader, Button } from "antd";
import FeatureRequestModal from "./FeatureRequestModal";
import UpcomingFeaturesModal from "./UpcomingFeaturesModal";
import DashboardFooter from "./DashboardFooter";
import {
  DashboardContentStyles,
  DashboardHeaderStyles,
  FeatureCard,
} from "./styles";

const Dashboard: React.FC = () => {
  const [modalVisiblity, setModalVisibility] = useState({
    upcomingFeatures: false,
    featureRequest: false,
  });

  const closeAllModals = () =>
    setModalVisibility({
      featureRequest: false,
      upcomingFeatures: false,
    });

  return (
    <ModuleLayout>
      <ModuleHeader title="Welcome to the MFG App" showBeta={false} />

      <DashboardContentStyles>
        <DashboardHeaderStyles>
          <PageHeader
            backIcon={false}
            title="Features"
            extra={[
              <Button
                key="2"
                onClick={() =>
                  setModalVisibility({
                    featureRequest: true,
                    upcomingFeatures: false,
                  })
                }
              >
                Request a feature
              </Button>,
              <Button
                key="1"
                type="primary"
                onClick={() =>
                  setModalVisibility({
                    featureRequest: false,
                    upcomingFeatures: true,
                  })
                }
              >
                Upcoming features
              </Button>,
            ]}
          />
        </DashboardHeaderStyles>

        <FeatureRequestModal
          visible={modalVisiblity.featureRequest}
          onCancel={closeAllModals}
          onOk={() => {
            closeAllModals();
          }}
        />

        <UpcomingFeaturesModal
          visible={modalVisiblity.upcomingFeatures}
          onCancel={closeAllModals}
        />

        <span style={{ fontSize: "1.2rem" }}>
          Here are a few features to get you started:
        </span>
        <div className="inner-container">
          <FeatureCard>
            <Link to="./dcf">
              <span className="feature-inner">
                Basic company <br /> valuation model
                <span className="decorator">
                  <BlockOutlined />
                </span>
              </span>
            </Link>
          </FeatureCard>

          <FeatureCard>
            <Link to="./gordon">
              <span className="feature-inner">
                Gordon <br /> growth model
                <span className="decorator">
                  <RiseOutlined />
                </span>
              </span>
            </Link>
          </FeatureCard>

          <FeatureCard>
            <Link to="./equity-research">
              <span className="feature-inner">
                Search for <br /> a company
                <span className="decorator">
                  <SearchOutlined />
                </span>
              </span>
            </Link>
          </FeatureCard>

          <FeatureCard>
            <Link to="./opcalc">
              <span className="feature-inner">
                Black-Scholes <br /> option calculator
                <span
                  className="decorator"
                  style={{ marginTop: "-1.7rem", fontSize: "5.8rem" }}
                >
                  σ
                </span>
              </span>
            </Link>
          </FeatureCard>
        </div>
      </DashboardContentStyles>
      <DashboardFooter />
    </ModuleLayout>
  );
};

export default Dashboard;
