export const unixTimestampToDate = (unixTimestamp: number) => {
  const date = new Date(unixTimestamp * 1000);
  return date.toDateString();
};

/**
 * Returns the a date (the current date by default), following the format of
 * `yyyy-mm-dd`.
 *
 * @param opts
 *     Optionally specify what date should be returned, relative to the current
 *     date. For instance, a string of the day one year ago can be retrieved as:
 *     `getFormattedDateString({ yearDelta: -1 })`.
 */
export const getFormattedDateString = (
  opts?: Partial<{
    monthDelta: number;
    yearDelta: number;
    dayDelta: number;
  }>
) => {
  const today = new Date();

  const year = today.getFullYear() + (opts?.yearDelta || 0);
  const month = today.getMonth() + (opts?.monthDelta || 0) + 1; // January is 0
  const day = today.getDate() + (opts?.dayDelta || 0);

  const fMonth = String(month).padStart(2, "0");
  const fDay = String(day).padStart(2, "0");

  return `${year}-${fMonth}-${fDay}`;
};
