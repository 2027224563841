import React from "react";
import { PageHeader, Tag } from "antd";
import styled from "styled-components";

const Wrapper = styled.span`
  padding: 0.5rem 0;

  .ant-page-header-back {
    font-size: 1.2rem;
  }

  .ant-page-header-heading-title {
    font-size: 2.2rem;
    padding-right: 0.3rem;
    line-height: 2.5rem;
  }

  .ant-page-header-heading-sub-title {
    font-size: 1.2rem;
    align-self: flex-end;
  }

  @media (max-width: ${(props) => props.theme.breakpointTablet}) {
    .ant-page-header-heading-left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .ant-page-header-heading-title {
      font-size: 1.5rem;
    }

    .ant-page-header-heading-sub-title {
      font-size: 0.8rem;
      align-items: flex-start;
    }
  }
`;

interface Props {
  title: string;
  onBack?: () => void;
  subTitle?: string;
  showBeta?: boolean;
}

const ModuleHeader: React.FC<Props> = ({
  title,
  subTitle = "",
  onBack = () => window.history.back(),
  showBeta = true,
}) => {
  return (
    <Wrapper>
      <PageHeader
        style={{ padding: "1rem 0 2rem" }}
        onBack={onBack}
        // hide back button manually
        backIcon={false}
        title={title}
        subTitle={subTitle}
        tags={showBeta ? <Tag color="geekblue">Beta</Tag> : undefined}
      />
    </Wrapper>
  );
};

export default ModuleHeader;
