import styled from "styled-components";

export const DashboardContentStyles = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  position: relative;
  min-height: 70vh;
  @media (max-width: ${(props) => props.theme.breakpointTablet}) {
    margin-bottom: 2rem;
  }

  .inner-container {
    display: flex;
    flex-flow: row wrap;
    margin-top: 1.5rem;
  }
`;

export const FeatureCard = styled.div`
  .feature-inner {
    background: white;
    margin: 2rem;
    padding: 2rem 1rem 1rem;
    border-radius: 5px;
    transition: box-shadow 0.5s;
    display: flex;
    align-items: flex-end;
    position: relative;
    min-height: 11rem;
    min-width: 16rem;
    max-width: 18rem;
    width: 100%;
    box-shadow: 5px 10px 23px 0 rgb(74 76 84 / 28%);
    &:hover {
      box-shadow: 10px 16px 28px 0 rgba(32, 33, 36, 0.28);
    }
    .decorator {
      color: #333333;
      position: absolute;
      top: 0;
      right: 16px;
      font-size: 5rem;
      opacity: 0.15;
      transition: all 0.5s;
    }
  }
  a {
    transition: all 0.1s;
    color: #333333;
    font-size: 1.5rem;
    &:hover {
      color: #0e3b90;
      font-size: 1.6rem;
      .decorator {
        opacity: 0.3;
      }
    }
  }
`;

export const DashboardHeaderStyles = styled.div`
  .ant-page-header {
    padding: 0;
    margin-bottom: 1.5rem;
    @media (max-width: ${(props) => props.theme.breakpointMobile}) {
      margin-bottom: 2rem;
    }
  }
  .ant-page-header-heading-title {
    font-size: 1.8rem;
    font-weight: 400;
    @media (max-width: ${(props) => props.theme.breakpointMobile}) {
      font-size: 2.2rem;
    }
  }
  .ant-btn {
    border: none;
    border-radius: 20px;
    box-shadow: 9px 9px 20px 0px rgb(74 76 84 / 19%);
    @media (max-width: ${(props) => props.theme.breakpointMobile}) {
      margin-top: 1rem;
    }
  }
  .ant-btn-primary {
    font-weight: 600;
  }
`;
