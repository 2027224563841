import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

export const Error = () => (
  <Layout style={{ height: "100vh" }}>
    <Content style={{ padding: "0 50px" }}>
      <h1 style={{ fontSize: "2rem" }}>Page not found</h1>
    </Content>
  </Layout>
);
